import api from '@services'

const serviceBaseUrl = process.env.REACT_APP_VEHICLE_BASE_URL ?? ''
const servicePrefix = `${serviceBaseUrl}/svc/vehicle/api`

//vehicle
const getAllVehicle = async () => {
  const response = await api.get(`${servicePrefix}/vehicles`)
  return response.data
}

const getVehicles = async (params) => {
  try {
    const response = await api.get(`${servicePrefix}/vehicles`, { params: stripEmptyObjects(params) })
    return response.data
  } catch (err) {
    console.log(err)
  }
}

const getVehicle = async (id) => {
  try {
    const response = await api.get(`${servicePrefix}/vehicles/${id}`)
    return response.data
  } catch (err) {
    console.log(err)
  }
}

export const searchVehicles = async (searchTerm) => {
  const response = await api.get(`${servicePrefix}/vehicles/search/${searchTerm}`)
  return response.data
}

export const newRcRenewalApplication = async (params) => {
  const response = await api.post(`${servicePrefix}/renewals/client`, params)
  return response.data
}

export const getRcRenewalApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/renewals/${id}`)
  return response.data
}

export const getAllOfficeLocations = async () => {
  const response = await api.get(`${servicePrefix}/office-locations/all`)
  return response.data
}

export const newRcReplacementApplication = async (params) => {
  const response = await api.post(`${servicePrefix}/replacements/client`, params)
  return response.data
}

export const getRcReplacementApplication = async (id) => {
  const response = await api.get(`${servicePrefix}/replacements/${id}`)
  return response.data
}

//transfer ownership
const addVehicleOwnershipTransfer = async (transfer) => {
  const response = await api.post(`${servicePrefix}/ownership-transfers/client`, transfer)
  return response.data
}

const updateVehicleOwnershipTransferAttachment = async (id, data) => {
  // console.log('hello', 'why this kolaberi')
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}/attachments-for-ownership-transfer`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  // console.log('response', response)
  return response.data
}

const vehicleOwnershipTransferPayment = async (transfer) => {
  const { id } = transfer
  const response = await api.patch(`${servicePrefix}/ownership-transfers/${id}/payment`, transfer)
  return response.data
}

const getTransferTypesAll = async () => {
  const response = await api.get(`${servicePrefix}/transfer-types/all`)
  return response.data
}

export const getVehicleOwnershipTransfer = async (id) => {
  const response = await api.get(`${servicePrefix}/ownership-transfers/${id}`)
  return response.data
}

export const getRoadWorthinessCertificatesRenewals = async (id) => {
  const response = await api.get(`${servicePrefix}/road-worthiness-certificate-renewals/search/${id}`)
  return response.data
}

export const getRoadWorthinessCertificatesRenewalsById = async (id) => {
  const response = await api.get(`${servicePrefix}/road-worthiness-certificate-renewals/${id}`)
  return response.data
}

//fetch application number details
export const getRcRegistrationByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/registrations/application-no/${applicationNo}`)
  return response.data
}

export const getRcRenewalByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/renewals/application-no/${applicationNo}`)
  return response.data
}

export const getRcReplacementByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/replacements/replacement-application-number/${applicationNo}`)
  return response.data
}

export const getRoadWorthinessByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/road-worthiness-certificate-renewals/renewal-application-number/${applicationNo}`)
  return response.data
}

export const getOwnershipTransferByApplicationNo = async (applicationNo) => {
  const response = await api.get(`${servicePrefix}/ownership-transfers/transfer-application-number/${applicationNo}`)
  return response.data
}

const VehicleService = {
  //vehicles
  searchVehicles,
  getAllVehicle,
  getVehicles,
  getVehicle,
  getAllOfficeLocations,
  newRcRenewalApplication,
  getRcRenewalApplication,
  newRcReplacementApplication,
  getRcReplacementApplication,
  addVehicleOwnershipTransfer,
  getTransferTypesAll,
  updateVehicleOwnershipTransferAttachment,
  vehicleOwnershipTransferPayment,
  getVehicleOwnershipTransfer,
  getRoadWorthinessCertificatesRenewals,
  getRoadWorthinessCertificatesRenewalsById,
  getRcRegistrationByApplicationNo,
  getRcRenewalByApplicationNo,
  getRcReplacementByApplicationNo,
  getRoadWorthinessByApplicationNo,
  getOwnershipTransferByApplicationNo
}

export default VehicleService
